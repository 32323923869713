import { useState, useEffect } from 'react'
import cookie from 'cookie'
import Cookies from '@/lib/cookies'
import api, { createApi } from '@/lib/api'
import { Head, Page } from '@/components'
import { CalculatorProvider } from '@/contexts/CalculatorContext'
import { InfoProvider } from '@/contexts/InfoContext'
import { CityProvider } from '@/contexts/CityContext'
import { AuthProvider } from '@/contexts/AuthContext'
import { SocketProvider } from '@/contexts/SocketContext'

import 'react-input-range/lib/css/index.css'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import 'react-datepicker/dist/react-datepicker.css'
import 'react-image-lightbox/style.css'
import 'react-dates/initialize'
import 'react-dates/lib/css/_datepicker.css'
import './Socials.css';

function parseCookies(req) {
    return cookie.parse(req ? req.headers.cookie || '' : document.cookie)
}

function App({
    Component,
    pageProps,
    router,
    categories,
    articles,
    massMedia,
    city,
    setCityCookie,
    areas,
}) {
    const [isLoading, setIsLoading] = useState(true)
    const [user, setUser] = useState(null)

    useEffect(() => {
        document.addEventListener('copy', (event) => {
          const pagelink = `\nЧитайте подробнее на: ${document.location.href}`;
          event.clipboardData.setData('text/plain', document.getSelection() + pagelink);
          event.preventDefault();
        });

        const boot = async () => {
            const token = Cookies.get('atl_token')

            if (token) {
                try {
                    const response = await api.me()
                    setUser(response.data)
                } catch (e) {
                    Cookies.deleteCookie('atl_token')
                }
                setIsLoading(false)
            } else {
                setIsLoading(false)
            }
        }

        boot()
    }, [])

    return (
        <>
            <Head />
            <CityProvider
                city={city}
                cities={areas.cities}
                areas={areas.areas}
                needSetCookie={setCityCookie}
            >
                <AuthProvider user={user}>
                    <SocketProvider>
                        <InfoProvider
                            articles={articles}
                            massMedia={massMedia}
                            categories={categories}
                        >
                            <CalculatorProvider>
                                {!isLoading && (
                                    <Page
                                        header={Component.options?.header ?? 'initial'}
                                        mobileHeader={Component.options?.mobileHeader ?? 'initial'}
                                        footer={
                                            (Component.options?.footer || pageProps?.footer) ??
                                            'initial'
                                        }
                                        userType={Component.options?.userType ?? 'all'}
                                    >
                                        <Component {...pageProps} key={router.route} />
                                    </Page>
                                )}
                            </CalculatorProvider>
                        </InfoProvider>
                    </SocketProvider>
                </AuthProvider>
            </CityProvider>
        </>
    )
}

App.getInitialProps = async ({ Component, ctx }) => {
    let pageProps = {}
    const ip = ctx.req?.headers['x-forwarded-for'] || ctx.req?.connection.remoteAddress
    const cookies = parseCookies(ctx.req)

    let city = cookies.atl_city ? JSON.parse(cookies.atl_city) : null
    let setCityCookie = false

    let sessionToken = cookies.atl_token
    const currentApi = ctx.req ? createApi(() => sessionToken) : api

    const areas = await currentApi.areas()

    if (ctx && ctx.query.slug && areas.cities.find((item) => item.slug === ctx.query.slug)) {
        city = areas.cities.find((item) => item.slug === ctx.query.slug)
        setCityCookie = true
    } else if (!city) {
        const response = await currentApi.cityFromIp({ ip })
        city = response.data
    }

    const categories = currentApi.categories({ city: city.slug })
    const articles = currentApi.articles.list()
    const massMedia = currentApi.articles.massMedia()

    if (Component.getInitialProps) {
        pageProps = await Component.getInitialProps({
            ...ctx,
            api: currentApi,
            city: city,
            cities: areas.cities,
        })
    }

    return {
        pageProps,
        categories: await categories,
        articles: await articles,
        massMedia: await massMedia,
        city: city,
        setCityCookie: setCityCookie,
        areas: areas,
        apiCacheData: currentApi.extractCache(),
    }
}

export default App
